<template>
	<div class="overflow-x-auto">
		<table class="my-4" aria-describedby="tableinput">
			<thead class="bg-white text-gray-darkest">
				<tr class="">
					<th v-for="(n, i) in headers"
						scope="col"
						:key="i"
						class="p-4 text-sm font-semibold tracking-wider border-b-2 border-secondary">
						<div :class="{
							'text-left': i !== 0 && i !== 8,
							'text-center': i === 0 || i === 8,
						}">
							<p
								:style="`width: ${i === 0 ? '2rem' : ''}; min-width: ${i === 6 || i === 8 ? '5rem' : i === 2 ? '20rem' : i === 3 || i === 4 ? '15rem' : i !== 0 ? '10rem' : ''};`">
								{{ n }}<span v-if="i === 1 || i === 2 || i === 6 || i === 7"
									class="text-error">*</span></p>
						</div>
					</th>
				</tr>
			</thead>
			<tbody class=" text-gray-dark">
				<tr v-for="(col, i) in rfqItemLists" :key="i">
					<td class="px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class="text-center"
							style="width: 2rem;">
							<p>
								{{ i + 1 }}
							</p>
						</div>
					</td>
					<td class="align-top px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class=""
							style="min-width: 10rem;">
							<div class="">
								<ValidationProvider name="Jenis"
									:vid="`Jenis${i}`"
									rules="max:128"
									v-slot="{ errors }">
									<SelectRfq size="small"
										:options="jenisOptions"
										:value="col.jenis"
										@input="updateError('Jenis', i)"
										:error="!!errors[0] || errorRequiredItemList?.[i]?.['Jenis']"
										@change="changeJenis($event, i)">
										<template #selected>
											<p>{{ col.jenis || 'Select' }}</p>
										</template>
										<template v-if="errors[0] || errorRequiredItemList?.[i]?.['Jenis']"
											#message>
											<p>{{ errors[0] || (errorRequiredItemList?.[i]?.['Jenis'] ? MESSAGE_EMPTY_FIELD : '') }}</p>
										</template>
									</SelectRfq>
								</ValidationProvider>
							</div>
						</div>
					</td>
					<td class="align-top px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class=""
							style="min-width: 20rem;">
							<ValidationProvider name="Product Layanan"
								:vid="`Product Layanan${i}`"
								rules="max:1000"
								v-slot="{ errors }">
								<InputGista type="text"
									v-model="col.productLayanan"
									size="small"
									@input="updateError('Product Layanan', i)"
									:error="!!errors[0] || errorRequiredItemList?.[i]?.['Product Layanan']"
									class="">
									<template v-if="errors[0] || errorRequiredItemList?.[i]?.['Product Layanan']"
										#message>
										<p>{{ errors[0] || (errorRequiredItemList?.[i]?.['Product Layanan'] ? MESSAGE_EMPTY_FIELD : '') }}</p>
									</template>
								</InputGista>
							</ValidationProvider>
						</div>
					</td>
					<td class="align-top px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class=""
							style="min-width: 15rem;">
							<ValidationProvider name="Sub Product"
								:vid="`Sub Product${i}`"
								rules="max:1000"
								v-slot="{ errors }">
									<InputGista type="text"
										v-model="col.subProduct"
										size="small"
										:error="!!errors[0]"
										class="">
										<template v-if="errors[0]"
											#message>
											<p>{{ errors[0] }}</p>
										</template>
									</InputGista>
							</ValidationProvider>
						</div>
					</td>
					<td class="align-top px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class=""
							style="min-width: 15rem;">
							<ValidationProvider name="Description"
								:vid="`Description${i}`"
								rules="max:1500"
								v-slot="{ errors }">
									<InputGista type="text"
										v-model="col.description"
										size="small"
										:error="!!errors[0]"
										class="">
										<template v-if="errors[0]"
											#message>
											<p>{{ errors[0] }}</p>
										</template>
									</InputGista>
							</ValidationProvider>
						</div>
					</td>
					<td class="align-top px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class=""
							style="min-width: 10rem;">
							<ValidationProvider name="Lokasi"
								:vid="`Lokasi${i}`"
								rules="max:200"
								v-slot="{ errors }">
									<InputGista type="text"
										v-model="col.lokasi"
										size="small"
										:error="!!errors[0]"
										class="">
										<template v-if="errors[0]"
											#message>
											<p>{{ errors[0] }}</p>
										</template>
									</InputGista>
							</ValidationProvider>
						</div>
					</td>
					<td class="align-top px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class=""
							style="min-width: 5rem;">

							<ValidationProvider name="Qty"
								:rules="`${rfqItemListValidation(i) ? 'decimalTwoDigits|min_one_with_decimal' : ''}`"
								:vid="`Qty${i}`"
								v-slot="{ errors }">
								<InputSolutip type="text"
									v-model="col.qty"
									size="small"
									@input="inputQtyAndUpdateError('Qty', i)"
									:error="!!errors[0] || errorRequiredItemList?.[i]?.['Qty']"
									class="">
									<template v-if="errors[0] || errorRequiredItemList?.[i]?.['Qty']"
										#message>
										<p>{{ errors[0] || (errorRequiredItemList?.[i]?.['Qty'] ? MESSAGE_EMPTY_FIELD : '') }}</p>
									</template>
								</InputSolutip>
							</ValidationProvider>
						</div>
					</td>
					<td class="align-top px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class=""
							style="min-width: 10rem;">
							<ValidationProvider 
								name="UOM"
								:vid="`UoM${i}`"
								rules="max:128"
								v-slot="{ errors }">
								<InputGista type="text"
									v-model="col.uom"
									size="small"
									@input="updateError('UoM', i)"
									:error="!!errors[0] || errorRequiredItemList?.[i]?.['UoM']"
									class="">
									<template v-if="errors[0] || errorRequiredItemList?.[i]?.['UoM']"
										#message>
										<p>{{ errors[0] || (errorRequiredItemList?.[i]?.['UoM'] ? MESSAGE_EMPTY_FIELD : '') }}</p>
									</template>
								</InputGista>
							</ValidationProvider>
						</div>
					</td>
					<td class="px-4 py-2 bg-opacity-25 border-b border-secondary-light"
						:class="{ 'bg-secondary-lightest': 1 === i % 2 }">
						<div class="flex justify-center w-full"
							style="min-width: 5rem;">
							<p v-if="rfqItemLists.length > 1"
								@click="removeItemList(i)"
								class="w-20 px-3 py-1 text-xl text-center cursor-pointer icon-trash-2 text-error hover:text-error-dark">
							</p>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import InputSolutip from "@/core/components/custom/Input"
import SelectRfq from "@/core/components/custom/SelectRfq"
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
	name: "tableInput",
	data() {
		return {
			jenisOptions: ['maintenance', 'software', 'hardware', 'subscription', 'services', 'link-kom'],
			headers: [
				'No', 'Jenis', 'Produk/Layanan', 'Sub Product', 'Description', 'Lokasi', 'Qty', 'UoM', 'Action'
			],
			MESSAGE_EMPTY_FIELD
		}
	},
	computed: {
		rfqItemLists: {
			get() {
				return this.$store.state.rfq.rfqItemLists
			},
			set(value) {
				this.$store.commit('rfq/SET_RFQ_ITEM_LISTS', value)
			}
		},
		errorRequiredItemList: {
			get() {
				return this.$store.state.errorRfq.errorRequiredItemList
			},
			set(value) {
				this.$store.commit('errorRfq/SET_ERROR_ITEM_LIST', value)
			}
		},
		
	},
	methods: {
		updateError(name, i) {
			if(this.errorRequiredItemList[i]) this.errorRequiredItemList[i][name] = false
		},
		removeItemList(index) {
			this.rfqItemLists = this.rfqItemLists.filter((e, i) => i !== index)
			this.errorRequiredItemList = this.errorRequiredItemList.filter((e, i) => i !== index)
		},
		changeJenis(val, i) {
			this.rfqItemLists[i].jenis = val
		},
		rfqItemListValidation(i) {
			const e = this.rfqItemLists[i]
			return (e.jenis || e.productLayanan || e.subProduct || e.description || e.lokasi || e.uom) || i === 0
		},
		inputQtyAndUpdateError(name, i) {
			this.updateError(name, i)
			const regexMatchDecimalTwoDigits = /^\d+\.?\d{0,2}/
			this.rfqItemLists[i].qty = this.rfqItemLists[i].qty.match(regexMatchDecimalTwoDigits)?.[0] || ''
		},
	},
	components: {
		InputSolutip,
		SelectRfq
	}
}
</script>

<style scoped>
.max-height-table {
	max-height: 20rem;
}
</style>